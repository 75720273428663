@use 'shared/shared';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/breakpoints';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/colors';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/colorsRGB';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/fontFamilies';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/fontSizes';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/fontWeights';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/gradients';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/letterSpacings';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/lineHeights';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/opacities';
@use '~@nbcnews/design-tokens/tokens/telemundoshows/spacing';
// Helper
@use '../utils/functions';

// GLOBAL VARIABLES
$theme: 'telemundoshows';

// Background
$default-background: colors.$white;

// Colors
$default-color: colors.$red-50;
$default-neutral: colors.$neutral-10;

// Gradient
$default-gradient: functions.to-list(gradients.$blue-blue-gradient);
$gradient-value-1: #27344f;
$gradient-value-2: #4ca3ff;

// Images
$default-img-border-radius: 0%;

// Links
$default-hover-opacity: opacities.$opacity-70;
$default-active-opacity: opacities.$opacity-50;

// Headlines
$default-headline-color: colors.$grey-70;
$default-headline-leading: lineHeights.$leading-100;
$default-headline-font-family: fontFamilies.$founders-cond;

// Body text
$default-text-size: fontSizes.$text-18;
$default-text-color: colors.$grey-70;
$default-text-leading: lineHeights.$leading-150;
$default-text-font-family: fontFamilies.$publico-txt;

:root {
  /* Colors */
  --default-color: var(--red-50);
  --default-color-rgb: var(--red-50-rgb);
  --default-neutral: var(--neutral-10);
  --default-neutral-rgb: var(--neutral-10-rgb);
  --primary-color: var(--default-color);
  --primary-color-rgb: var(--default-color-rgb);

  /* Gradient */
  --default-gradient: var(--blue-blue-gradient-stop-1), var(--blue-blue-gradient-stop-2);
  --gradient-value-1: var(--blue-blue-gradient-stop-1);
  --gradient-value-1-rgb: #{functions.color-to-rgb($gradient-value-1)};
  --gradient-value-2: var(--blue-blue-gradient-stop-2);
  --gradient-value-2-rgb: #{functions.color-to-rgb($gradient-value-2)};

  /* Images */
  --default-img-border-radius: 0%;

  /* Links */
  --default-hover-opacity: var(--opacity-70);
  --default-active-opacity: var(--opacity-50);

  /* Headlines */
  --default-headline-color: var(--grey-70);
  --default-headline-color-rgb: var(--grey-70-rgb);
  --default-headline-leading: var(--leading-100);
  --default-headline-font-family: var(--founders-cond);
  --primary-font: var(--default-headline-font-family);

  /* Body text */
  --default-text-size: var(--text-18);
  --default-text-color: var(--grey-70);
  --default-text-color-rgb: var(--grey-70-rgb);
  --default-text-leading: var(--leading-150);
  --default-text-font-family: var(--publico-txt);
  --secondary-font: var(--default-text-font-family);
}
